import React from 'react';
import { PathService } from '../services';
import { TemplateProps } from '../types';
import EmploymentScreeningView from '../views/employment-screening';

const EmploymentScreening: React.FunctionComponent<TemplateProps<
  undefined
>> = ({ location }) => {
  PathService.set(location.pathname);
  return <EmploymentScreeningView />;
};

export default EmploymentScreening;
